<template>
  <div class="pageEndpoint">
    Authorisation Response - post message
    <h1>{{ message }}</h1>
    <p>status: {{ result.status }}</p>
    <p>type: {{ result.type }}</p>
  </div>
</template>

<script>
export default {
  name: 'AuthResponse',
  components: {},
  data() {
    return {
      domainPath: process.env.VUE_APP_DOMAIN,
      message: '',
      result: {}
    }
  },
  mounted() {
    let params = this.$route.query
    this.result = params
    console.log(params)
    window.opener.postMessage(params, this.domainPath)
    window.close()
  },
  methods: {}
}
</script>
